body{
    /* background-image: url(../Images/toushif-alam-JZyBmdOpkpI-unsplash.jpg); */
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fffa93;
}

#googlemap{
 position: relative;
 /* margin-left: 120px; */
 z-index: 1;
 right: 2vw;

}
.reotp-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
}
button.re-btn {
    width: fit-content !important;
    background-color: transparent !important;
    color: #fff !important;
    transition: all .3s ease-in-out;
}
button.re-btn:hover {
    /* width: 6vw; */
    background-color: transparent !important;
    color: #fff !important;
    transform: scale(1.1);
    
}
button.re-btn1 {
    width: fit-content !important;
    background-color: transparent !important;
    color: #8f8f8f !important;
    transition: all .3s ease-in-out;
    
}
button.re-btn1:hover {
    /* width: 6vw; */
    background-color: transparent !important;
    color: #8f8f8f !important;
    transform: scale(1.1) !important;
}
.home_main{
    display: flex;
    gap: 4vw;
    justify-content: space-around;
    position: relative;
    padding: 1vw;

}
#search_box{
    position: relative;
    z-index: 2;
}