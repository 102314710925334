@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}


/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* #NavHome{
  background-color: #fae914;
  background-image: url(../src/Images/map.png);
  height: 120px;

  
} */
/* #button{
  display: flex;
  justify-content: flex-end;
} */

.log-btn{
  position: relative;
  right: 10px;
  background-color: aquamarine;
}

.chat-box {
  width: 200px;
  height: fit-content;
  position: fixed;
  bottom: 20px;
  right: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fbbf13;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99 !important;
  font-weight: 600;
}

.chat-header {
  padding: 10px;
}
#navbar_main_sticky{
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999 !important;
  border-radius: 0px !important;

}
#btn_hover_main{
  background-color: black !important;
  color: white !important;
  transition: all .3s ease-in-out;
  border: 1px solid transparent;
}
#btn_hover_main:hover{
  background-color: white !important;
  color: black !important;
  border: 1px solid grey;

}
#navbar_main_sticky button{
  background-color: black !important;
  color: white !important;
  transition: all .3s ease-in-out;
  border: 1px solid transparent;
  width: auto;
}
#navbar_main_sticky button:hover{
  background-color: white !important;
  color: black !important;
  border: 1px solid grey !important;
  width: auto;
}
#mobile_menu button{
  background-color: white !important;
  color: black !important;
  transition: all .3s ease-in-out;
  border: 1px solid transparent;
}
#mobile_menu button:hover{
  background-color: black !important;
  color: white !important;
  border: 1px solid grey !important;
}
.Toastify__toast-container {
  position: fixed;
  z-index: 999999 !important; /* Set your desired z-index */
}
.css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  color: transparent;
  padding: 8px;
}
