
  .signup-form {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    position: absolute;
    top: 12%;
  }
  
  .signup-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  
  input[type="text"],
  input[type="email"],
  input[type="password"] ,
  input[type="number"] {
    width: 100%;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    outline: none;
    background-color: rgb(255 255 255 / 90%);
    color: black;
    font-weight: 600;
  }
  input::placeholder{
    color: black;
    font-weight: 500;
  }
  /* button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  } */
  