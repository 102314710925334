  
  .login-form {
    /* background-color: #f1f1ee00; */
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    width: 400px;
    position: absolute;
    top: 20%;
    right:40px;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
/* #login-btn {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    font-weight: 700;
    padding: 10px 40px;
    cursor: pointer;
    width: 50%;
    transition: background-color 0.3s;
  }
  
  #login-btn:hover {
    background-color: #fff;
    color: #000;
  }  */
  input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield; 
}
  