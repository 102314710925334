.profile-editor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: transparent;
  }
  .profile-form h2{
    font-weight: 600;
    font-size: 24px;
  }
  
  .profile-view, .profile-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 30vw;
    width: 100%;
    padding: 2vw;
  }
  
  .profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  
  
  .profile-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .profile-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .profile-form .buttons {
    display: flex;
    justify-content: space-between;
  }
  
  /* .profile-form .buttons button {
    width: 48%;
  } */
  /* #edit_profile{
    background-color: black !important;
    color: white !important;
  } */
 .profile_box{
  display: flex;
  justify-content: space-around;
  align-items: center;
 } 
 .profile_box_right{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 }