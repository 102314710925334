.chat-popup {
    width: 300px;
    height: max-content;
    position: fixed;
    bottom: 100px;
    right: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ffffffad;
    display: block; /* Initially hidden */
    z-index: 9999999 !important;
  }
  
  .popup-header {
    padding: 10px;
    background-color: #fbbf13;
    color: black;
    text-align: center;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
  }
  
  .chat-messages {
    padding: 10px;
    height: 300px;
    overflow-y: auto;
  }
  .user-message {
    background-color: #d1ffd1;
    align-self: flex-end;
    width: fit-content;
    padding: 0 20px;
    border-radius: 10px;
  }
  
  .driver-message {
    background-color: #ffe0e0;
    align-self: flex-start;
    width: fit-content;
    padding: 0 20px;
    border-radius: 10px;
  }
  
  .chat-message {
    margin-bottom: 10px;
  }
  
  input#chat_input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    
  }
 
  
  /* button#chat_with_us {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px;
    background-color: #fbbf13;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  } */
  