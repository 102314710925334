/* App.css */
/* body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  } */
  
  /* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
   */

  
  
  h1 {
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
  }
  
  .login-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 25px;
  }
  
  .option {
    padding: 10px 20px;
    border: 1px solid #ccc;
    background-color: #fff;

    color: #000;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .option:hover {
    background-color: #000;
    color: #fff;
  }
  
  .option.active {
    background-color: #000;
    color: #fff;
  }
  
  
  
  input[type='email'],
  input[type='password'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s, color 0.3s;
    background-color: #fff !important;
    color: #000;
  } */
  .cc001{
    color: #fff;
  }
  
  /* button:hover {
    background-color: #000 !important;
    color: #fff;
  } */
  
  .signup-container {
   
    
      background-color: #00000090;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 70px 55px;
      border-radius: 5px;
      
  }
  .signup-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    
  }