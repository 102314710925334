/* about us css starts */

.heading001{
    color: blue;
}
.title_main{
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}
.line001{
 height: 2px;
 width: 5vw;
 background-color: black;
}
.text001{
    font-size: 21px;
    font-weight: 900;
    line-height: 33px;
    color: #517988;
    font-style: italic;
}
.text002{
    text-align: justify;
    line-height: 33px;
    font-size: 19px;
    font-weight: 600;
}
/* about us css ends */

/* our partner css starts */
.partner001{
    display: flex;
    border-radius: 20px;
    /* box-shadow:  0 0 5px -2px black; */
}
.left001{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 125px;
}
.phead001{
    text-align: left;
    font-size: 39px;
}
.phead002{
    text-align: left;
    font-size: 75px;
    color: #00000094;
}
.right001{
    width: 50%;
}
.logos{
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    gap: 75px;
}

.logo001 , .logo002 , .logo003 , .logo004 , .logo005 , .logo006{
    height: 4vw;
    width: 10vw;
}
/* our partner css ends */

/* gallary css starts */
.gallary001{
    text-align: left;
}
.gheading{
    font-size: 43px;
}
    .gtext001 {
        font-size: 28px;
        color: #616161;
        padding: 15px 0px;
        font-weight: 700;
    }


.gtext002{
    font-size: 21px;
    font-weight: 600;
    color: #898989;
}
/* gallary css ends */



/* contact css starts */
.contactmain {
    position: relative;
    background-color: #719cf800;
    background-image: url(./Images/taxi_inscription_words_163389_1920x1080.jpg);         
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: 90vh;
    width: 100%;
    background-position: left;
    margin-top: -25px;
}
.cu_overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
}
.cu_parent{
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}
.cheading001{
    color: #ffffff;
    font-size: 54px;
    padding-top: 25px;
}
.cutext001{
    color: #fff;
    font-size: 19px;
}
.cu{

    display: flex;
    gap: 150px;
    padding-top: 50px;
}
.cu01 {
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.cu_icon_box{
    display: flex;
    gap: 15px;
}
.cu_icon {
    background-color: #fff;
    width: 2vw;
    height: 2vw;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cu_icon_box_heading{
    color: #fff200;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
}
.cu_link{
    color: #fff200;
}
.cu_icon_box_para{
    color:#fff;
    text-align: left;
}
.cu02{
    /* background-color: #000; */
}
select#inputState {
    background-color: rgb(255 255 255 / 90%);
    padding: 10px 10px;
    border: 1px solid #ffffff6b;
}
#inputZip {
    width: 80%;
}
#inputEmail4 {
    border: 1px solid #ffffff6b;
}
.cu_icon_box_para{
    font-size: 19px  ;
}
button#btn_cu {
    border: 1px solid #000000;
    background-color: #fff !important;
    color: #000;
}
button#btn_cu:hover{
    background-color: #000 !important;
    color: #fff;
}
/* .c-box-main {
    display: flex;
    gap: 25px;
    padding-bottom: 50px;
    justify-content: center;
}
.c-box001 {
    width: 25vw;
    height: 18vw;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    align-items: center;
    
}
.ctext001{
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    padding-bottom: 50px;
    
}
.cimage{
    background-color: #fff;
    height: 6vw;
    width: 6vw;
    border-radius: 60px;
    transform: translate(0px, -25px);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}
.ctitle{
    font-size: 31px;
    font-weight: 600;
    color: #000000;
}
.ctext {
    font-size: 19px;
    padding: 0px 50px;
}
.btn001{
    height: 4vw;
    width: 18vw;
    font-size: 28px;
    background-color: #fff;
    border: none;
    border-radius: 35px;
}
.btn001:hover {
    border:3px solid #ffffff;
} */
/* contact css ends */


/* our team  css starts */

.ourteam-main{
    display: flex;
    box-shadow: 0px 0px 5px #000;
    margin-top: 50px;
    border-radius: 15px;

}
.ourteam001{
    width: 50%;
}
.ourteam002{
    width: 50%;
}
.ourcontent001{
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-align: left;
    padding: 75px 0px;
}
.ourheading h2{
    font-size: 52px;
    color: #001465;
}
.ourtext{
    font-size: 24px;
}
button.obtn001 {
    width: 10vw;
    height: 3vw;
    font-size: x-large;
    color: white;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    background-color: #FFBA48 !important ;
    box-shadow: 0 0 5px -1px black !important;
}
#blog-btn001{
    background-color: #000 !important;
    color: white !important;
    box-shadow: 0 0 5px -2px;
    transition: transform .3s ease-in-out;
}
#blog-btn001:hover{
    background-color: #000 !important;
    color: #fff;
    transform: scale(1.04);
}
#black_underline{
    height: 2px;
    width: 10vw;
    background-color: #000;
}
/* our team  css ends */

/* blogs css starts */
.blog-main {
   height: 100vh;
   width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.blog-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4vw;
    position: relative;
    width: 20vw;
    height: 30vw;
    box-shadow: 0px 0px 5px;
    border-radius: 20px;
    background-color: #ffffff;
}


.blog-image{
    width: 20vw;
    height: 8vw;

}
.blog-image img{
    
    border-radius: 20px 20px 0px 0px;
    
}
.blog-box001{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1vw;
    margin-top: 1vw;
}
.blog-title {
    background-color: #c6c6c6;
    border-radius: 26px;
    width: 7vw;
    height: auto;
    font-size: 21px;
    font-weight: 500;
}
.blog-heading {
    font-size: 27px;
    font-weight: 600;
    text-align: left;
    width: 19vw;
}
.blog-text001 {
    background-color: #ccc;
    width: 6vw;
    height: auto;
    font-size: 16px;
    border-radius: 16px;
}
.blog-text {
    display: flex;
    gap: 10px;
    width: 19vw;
}
.blog-btn001 {
    width: 8vw;
    border: none;
    border-radius: 29px;
    font-size: 24px;
}
/* blogs css ends */



/* faq css starts */

.faq-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.faq-head{
    font-size: 36px;
    font-weight: 500;
    color: #001465;
}
.faq-title {
    font-size: 28px;
    font-weight: 500;
    color: #001c8fa1;
}
.faq-text{
    font-size: 24px;
    font-weight: 500;
    color: #001c8fa1;
}
.faq-img {
    background-color: #001465;
    width: 10vw;
    height: 10vw;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.f-line {
    width: 100%;
    display: flex;
    justify-content: center;
}
.faq-line{
    background-color: #818181a1;
    width: 70vw;
    height: 2px;
    margin-top: -50px;
}
.f-acor {
    display: flex;
    gap: 100px;
    padding: 50px 0px;
    background-color: #fff;
    z-index: 1;
    position: relative;
    margin-top: -48px;
}
.accordion1 ,.accordion12{
    width: 50%;
}
h2.f-heading001 {
    padding-bottom: 15px;
}
/* faq css ends */
/* .p-main {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 35px;
} */
/* .div1{
    font-size: 36px;
    font-weight: 600;
} */
/* .div2{
    font-size: 24px;
    font-weight: 500;
    text-align: left;
} */
.price-box-main{
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 50px;
}
.price-box {
    background-color: rgb(255, 255, 255);
    width: 19vw;
    max-height: 38rem;
    border-radius: 15px;
    box-shadow: 0px 0px 5px #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.p-box-txt {
    font-size: 46px;
    font-weight: 400;
    padding: 15px;
}
.p-box-price {
    font-size: 90px;
    font-weight: 700;
    padding-bottom: 75px;
}
.p-box-points {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 15px;
    font-size: 21px;
    padding-left: 35px;
}
.p-point3 {
    display: flex;
    align-items: center;
    gap: 15px;
}
.p-point003 {
    font-size: 10px;
    padding: 7px;
    border: 1px solid #000;
    border-radius: 15px;
}
button.price-btn007 {
    width: 10vw;
    padding: 15px 0px;
    margin: 75px 0px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px -8px #000 !important;
}
.price-btn007{
    background-color:#000 !important ;
    transition: transform .4s ease-in-out;
    color: white;
    font-weight: 500;

}
.price-btn007:hover{
    transform: scale(1.1);


}

.price-txt {
    font-size: 57px;
    font-weight: 700;
    padding: 24px 0px;
}
.pricr-txt02 {
    font-size: 37px;
    font-weight: 600;
    padding-bottom: 25px;
}
#main-box001 {
    padding: 1vw;
    position: absolute;
    z-index: 1;
    top: 250px;
    left: 100px;
    width: 25vw;
    height: 20vw;
    background-color: rgba(0, 0, 0, 0.623);
    top: 120px;
    right: 50px;
    border-radius: 10px;
    box-shadow: 0 0 200px -10px black;
}
.home-image001 img {
    /* height: 100vh; */
    width: 100%;
}
/* #main_component {
    padding-top: 4vw;
} */
.css-jzIGNN001{
    color: #fff;
}
/* #banner_img_home img{
    width: 100%;
    height: 100%;
} */
#banner_img_home {
    background-image: url(./Images/IMG-20241005-WA0040.jpg);
    background-size:cover;
    background-position: 10% 10%;
    background-repeat: no-repeat !important; 
}
.home2 {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: -5vw;
}
.login001{
    /* width: 20vw;
    height: 20vw; */
    background-color: rgba(0, 0, 0, 0.623);
    /* position: absolute; */

    z-index: 1;
    /* top: 120px; */
    /* right: 50px; */
    display: flex;
    border-radius: 10px;
    /* box-shadow: 0 0 200px -10px black; */
}
.login-container{
    background-color: #00000090;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    padding:70px 55px;
    border-radius: 5px;
    /* width: 50%; */
}
.login-container h1{
    color: #fff;
}
/* .login001{
    margin-top: -25px;
} */
.accord-main {
    display: flex;
    gap: 175px;
    padding-bottom: 100px;
}
.accord{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 25vw;

}
.accord-001{
    display: flex;
    gap: 1px;
    background-color: #fff;
    padding: 10px 25px;
    width: 20vw;
    border: 1px solid #000;
}
#accord-btn{
    width: 25%;
    padding: 0px 25px;
}
.accord-text {
    width: 20vw;
    text-align: justify;
    padding: 15px;
    background-color: #fff;
}
.accord-1, .accord-2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.faq-img img {
    width: 7vw;
    height: 15vh;
}
.gall-main{
    padding: 25px 0px;
}
.gall-1 {
    display: flex;
    gap: 15px;
    padding-top: 15px;
}
.g-image1 img, .g-image3 img,.g-image5 img {
    width: 100vw;
    height: 25vh;
}
.g-image2 img, .g-image4 img, .g-image6 img {
    height: 25vh;
    width: 70vw;
}
#how_it_works{
    margin-top: -25px;
    width: 100%;
    height: 89.3vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(./Images/lexi-anderson-G8wPrJyNqWQ-unsplash.jpg);
    background-position: center;

    /* background-color: #e9b21c ; */
}
.works-main {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 30vw;
    width: 50vw;
    align-items: center;
    justify-content: start;
    background-color: rgba(0, 0, 0, 0.63);
    border-radius: 10px;
    box-shadow: 0 0 150px -20px black;
}
.work-left{
    /* height: 50%; */
    display: flex;
    flex-direction: column;
    /* gap: 35px; */
    justify-content: space-between;
    align-items: center;
}
.work-box{
    padding: 10px;
}
.work-right{
    width: 100%;
    height: 100%;
    /* border: 5px solid #000; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    background-color: rgba(255, 255, 255, 0.938);
    padding: 20px 15px;
    border-radius: 25px;
    box-shadow: 0 0 50px -20px black;

    
}
button.work-box {
    background-color: rgba(255, 255, 255, 0.938);
width: 15vw;
    font-weight: 700;
}

/* @media-screen and (max-width:767px){ */
    @media only screen and (max-width: 767px){
    button.work-box {
    width: 25vw;
    }
}
button.work-box:hover {
    background-color: #000 !important;
}
.text-box {
    font-size: 28px;
    font-weight: 700;
    color: #000;
}

.ourteam002 img{
    border-radius: 15px 0px 0px 15px;
    margin-left: -12px;
}
.css-dmdqlf-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    background-color: #f3f3f3e1 !important;
    border-color: hsl(0, 0%, 80%);
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    border: none;
    padding: 20px;
    margin: 10px 25px;
}
#main{
    position: absolute;
}
#current_location{
    position: absolute;
    right: 2.8rem;
    top: .6rem;

}
.bg_home_right img{
    border-radius: 15px;
}
.sign_up_page_main{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.login_page_main{
    display: flex;
    justify-content: end;
    align-items: center;
}

  